import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import './FightLandingPage.css';

function FightLandingPage() {
    const [games, setGames] = useState([]);
    const [autoplay, setAutoplay] = useState(false);
    const [gameName, setGameName] = useState(''); // New state for game name
    const SERVER_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate("/");
    };

    const validateGameName = (name) => {
        const trimmedName = name.trim();
        return trimmedName.length > 0 && trimmedName.length <= 50;
    };

    const createChessGame = async () => {
        if (!validateGameName(gameName)) {
            alert('Please enter a valid game name (1-50 characters).');
            return;
        }
        try {
            const response = await fetch("/api/fighting/game", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ gameName, autoplay }) // Pass game name and player name in POST request
            });
            console.log(response);
            if (!response.ok) throw new Error("Failed to create game");
            const game = await response.json();
            const gameLink = `/mferfight/${game.gameId}`;
            console.log("navigating to game link");
            navigate(gameLink); // to redirect
        } catch (error) {
            console.error("Error creating game:", error);
        }
    };

    const joinGame = (uuid) => {
        window.location.href = `/mferfight/${uuid}`;
    };

    return (
        <div className="game-container">
            <h1 className="title">Mfer Fight</h1>

            <section>
                <h3>Create a New Game</h3>
                <p>
                    <label>Game Name:
                        <input
                            type="text"
                            placeholder="Enter a Name for New Game"
                            value={gameName}
                            onChange={(e) => setGameName(e.target.value)}
                        />
                    </label>
                </p>

                <button className="create-game-button" onClick={createChessGame}>
                    Create New Game
                </button>
            </section>
            <button onClick={navigateToHome} className="back-button">
                Back to Home
            </button>
        </div>
    );
}

export default FightLandingPage;
