// External Libraries
import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";

// Styles
import "./Fighting.css";
const SERVER_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

function FightingGame() {
    const canvasRef = useRef(null);
    const [playerState, setPlayerState] = useState({
        x: 100,
        y: 300,
        velocityX: 0,
        velocityY: 0,
        health: 100,
    });

    useEffect(() => {
        // Establish WebSocket connection
        const socket = io(`${SERVER_URL}/fighting`); // Connect to the `/fighting` namespace

        // Handle game state updates from the server
        socket.on("gameUpdated", (state) => {
            setPlayerState(state.player); // Update player state
        });

        socket.on("notify", (message) => {
            console.log("Server Notification:", message);
        });

        // Handle keyboard events
        const handleKeyDown = (event) => {
            if (["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(event.key)) {
                socket.emit("keyEvent", { key: event.key });
                console.log(`Key pressed: ${event.key}`);
            }
        };

        // Attach event listener
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            socket.disconnect();
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        const render = () => {
            // Clear the canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Draw the arena
            ctx.fillStyle = "#d3d3d3"; // Light grey background
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Draw the player sprite
            drawPlayer(ctx, playerState.x, playerState.y, playerState.health);

            requestAnimationFrame(render);
        };

        render(); // Start rendering loop

        return () => {
            // No cleanup needed for render loop since the component unmount handles it
        };
    }, [playerState]);

    const drawPlayer = (ctx, x, y, health) => {
        // Draw health bar
        ctx.fillStyle = "green";
        ctx.fillRect(x - 25, y - 50, (health / 100) * 50, 5);

        // Draw stick figure
        ctx.beginPath();
        ctx.arc(x, y - 40, 20, 0, Math.PI * 2, true); // Head
        ctx.fillStyle = "black";
        ctx.fill();

        ctx.beginPath();
        ctx.moveTo(x, y - 20); // Body
        ctx.lineTo(x, y + 40);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(x, y); // Arms
        ctx.lineTo(x - 30, y - 20);
        ctx.moveTo(x, y);
        ctx.lineTo(x + 30, y - 20);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(x, y + 40); // Legs
        ctx.lineTo(x - 20, y + 80);
        ctx.moveTo(x, y + 40);
        ctx.lineTo(x + 20, y + 80);
        ctx.stroke();
    };

    return (
        <div className="platformer-game-container">
            <h2>Platformer Game</h2>
            <canvas ref={canvasRef} width="800" height="400" className="platformer-game-canvas"></canvas>
        </div>
    );
}

export default FightingGame;
